
/*
 ORDER STEPS PROGRESSBAR
*/
.order-steps{
    .col-sm-3 + .col-sm-3{
        padding-left: 1px;
    }
    &__item{
        display: block;
        height: 60px;
        line-height: 60px;
        padding-left: 20px;
        font-size: 0;
        text-decoration: none;
        color:#cfcfcf;
    }
    &__title,
    &__number{
        display: inline-block;
        vertical-align: middle;
        font-weight:700;
    }
    &__title{
        line-height: 1;
        font-size: 14px;
        color: #cfcfcf;
    }
    &__number{
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin-right: 20px;
        font-size:17px;
        border-radius:50%;
        color:#fff;
        background: #cfcfcf;
    }
    @media(max-width: $screen-sm - 1){
        padding-left:0;
    }
}

// ACTIVE STATE
.order-steps__item--active{
    .order-steps__title{
        text-decoration:underline;
        color: $brand-primary;
    }
    .order-steps__number{
        text-decoration: none;
        color:#fff;
        background: $brand-primary;
    }
}

// COMPLETE STATE
.order-steps__item--complete{
    .order-steps__title{
        color: lighten($brand-primary,20%);
    }
    .order-steps__number{
        text-decoration: none;
        color:#fff;
        background: lighten($brand-primary,20%);
    }
}

// HOVER
.order-steps__item:hover{
    .order-steps__title{
        text-decoration: underline;
    }
}
// THIRD STEP - SHIPPING & DELIVERY
#payment-list {
    input {
        display: none;
    }
    div {
        margin-bottom: 5px;
    }
    label.btn {
        position: relative;
        height: 46px;
        line-height: 46px;
        padding-left: 46px;
        box-shadow: none;
        color: #fff;
        background: #000;
        border-color: #000;
        border-radius: 0;

        .glyphicon {
            position: absolute;
            display: block;
            left: 14px;
            top: 14px;
            width: 18px;
            height: 18px;
            line-height: 16px;
            font-size: 0;
            border: 1px solid #fff;
            border-radius: 2px;
        }
    }
    label.btn.active {
        background: $brand-primary;
        border-color: $brand-primary;
        color: #fff;

        .glyphicon {
            font-size: 10px;
        }
    }
}