// MENU
@media (min-width: $screen-sm){
    #top-menu {
        ul,
        li {
            float: none;
        }
        a {
            text-decoration: none;
            white-space: normal;
        }
        .nav {
            ul {
                position: absolute;
                top: 100%;
                left: 0;
                width: 220px;
                padding: 10px 0;
                border: 0;
                border-radius: 0;
                ul {
                    left: 100%;
                    top: 0;
                    margin-top: -10px;
                }
            }
            li.last > ul {
                left: auto;
                right: 0;
            }
        }
        li {
            display: inline-block;
            position: relative;
            &:hover > ul {
                display: block;
            }
        }
        .nav > li {
            &.last:hover > ul {
                left: auto;
                right: 0;
            }
            li {
                display: block;
                a {
                    padding: 5px 20px;
                }
                &:hover > a {
                    background: darken($brand-primary, 5%);
                }
            }
        }
    }
}