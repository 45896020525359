$plugin-height: 66px;

/* Fade in up
------------------------------------ */
@-webkit-keyframes slideUp {
    0% {

        -webkit-transform: translateY($plugin-height);
        transform: translateY($plugin-height);
    }

    100% {

        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slideUp {
    0% {

        -webkit-transform: translateY($plugin-height);
        -ms-transform: translateY($plugin-height);
        transform: translateY($plugin-height);
    }

    100% {

        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}



.cc_container,
.cc_message,
.cc_btn {

    animation-duration: 0.8s;
    -webkit-animation-duration: 0.8s;
    -moz-animation-duration: 0.8s;
    -o-animation-duration: 0.8s;

    -webkit-animation-name: slideUp;
    animation-name: slideUp;
}

