// CART DROPDOWN
#cart-dropdown {
    display: inline-block;

    .dropdown-menu {
        padding: 0;
        min-width: 270px;
        overflow: hidden;
    }
    .table {
        margin: 0;
        tr:first-child td {
            border-top: 0;
        }
    }
    .buttons {
        padding: 10px 15px;
        border-top: 1px solid #ccc;
    }
    .btn-trash {
        padding: 0;
        border-color: transparent;
        background: none;
        color: $text-color;
    }
}

// CART PRODUCT LIST
#cart-product-list {}

// CART SUBTOTALS
#cart-subtotal,
#order-total {
    table {
        float: right;
        width: 38%;
        background: rgba(204, 204, 204, 0.15);
    }
    tr:first-child {
        th,
        td {
            border: 0;
        }
    }
}
