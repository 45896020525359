@import "../variables";

$plugin-height: 66px !default;
$logo: '';

// Themes, uncomment only the one you wanna use!
/*

// Dark floating
@import '../addons/cookieconsent2/floating';
@import '../addons/cookieconsent2/dark';
@import '../addons/cookieconsent2/fadeinright';

// Dark floating tada
@import '../addons/cookieconsent2/floating';
@import '../addons/cookieconsent2/dark';
@import '../addons/cookieconsent2/tada';

// Dark inline
@import '../addons/cookieconsent2/inline';
@import '../addons/cookieconsent2/dark';

// Dark top
$plugin-height: 66px; //--uses height for an offset
@import '../addons/cookieconsent2/top';
@import '../addons/cookieconsent2/dark';
@import '../addons/cookieconsent2/pushdown';

// Light top
$plugin-height: 66px;
@import '../addons/cookieconsent2/top';
@import '../addons/cookieconsent2/light';
@import '../addons/cookieconsent2/pushdown';

// Light bottom
@import '../addons/cookieconsent2/bottom';
@import '../addons/cookieconsent2/light';
@import '../addons/cookieconsent2/slideup';
*/

// Light floating
@import '../addons/cookieconsent2/_floating';
@import '../addons/cookieconsent2/_light';
@import '../addons/cookieconsent2/_fadeinright';
@media screen and (min-width: $screen-xs-min) {
  .cc_container {
    border-radius:5px;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.2)
  }
}


// Custom styles
.cc_banner{
  z-index:999;
}
.cc_container{
  font-family: $font-family-base;
  border:0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  .cc_message{
    font-size: $font-size-base;
    line-height: $line-height-base;
  }
  .cc_btn,
  .cc_btn:visited,
  .cc_btn:hover
  {
    font-size: $font-size-base;
    color: $btn-primary-color;
    text-decoration:none;
    background: $btn-primary-bg;
  }
  .cc_btn:hover{
    background: darken($btn-primary-bg,10%);
  }
  .cc_logo{
    display:none;
  }
  a, a:visited{
    color: $brand-primary;
  }
}
@media screen and (min-width: $screen-xs){
  .cc_container {
    padding-bottom: 60px;
  }
  .cc_container .cc_btn {
    bottom: 15px;
  }
}
@media screen and (max-width: $screen-xs){
  @import '../addons/cookieconsent2/slideup';
}