// BASICS
#content {
    ul li {
        position: relative;
        display: block;
        padding: 2px 0 2px 17px;
    }
}
#content .category-menu {
    ul {
        margin: 15px -5px;
        li {
            background: transparent;
            padding: 10px 10px 10px 70px;
            position: relative;
            border: 1px solid #e6e6e6;
            height: 70px;
            line-height: 70px;
            margin: 2px 5px;
            border-radius: 5px;
            img {
                position: absolute;
                top: 10px;
                left: 10px;
                max-width: 50px;
                max-height: 50px;
            }
            a {
                padding: 0;
                background: transparent;
                height: 50px;
                line-height: 50px;
                color: #333;
                position: static;
            }
            &:hover {
                background: #e6e6e6;
            }
        }
    }
}

// NEWS
#page-news {
    .item {
        position: relative;

        .item-inner {
            width: auto;
            margin-left: 89px;
            min-height: 74px;
        }
        &.with-image{
            .item-inner{
                @media(min-width: $screen-sm){
                    min-height:140px;
                    margin-left: 305px;
                }
            }
        }
        + .item{
            margin-top: 35px;
        }
    }
    .item-date {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 74px;
        height: 74px;
        color: #fff;
        text-align: center;
        background:$brand-primary;

        span {
            display: block;
            color: #fff;
            line-height: 1.4;
        }
    }
    .item-date-d {
        padding-top: 16px;
        font-size: 16px;
    }
    .item-date-y {
        font-size: 14px;
    }
    .item-title {
        display: block;
        margin-bottom: 7px;
        line-height: 1;
        font-size: 25px;
        color: #000;
        font-weight: 700;
    }
    .item-image {
        position: absolute;
        top: 0;
        left: 89px;
        width: 200px;
        text-align: center;
        font-size: 0;

        img{
            vertical-align: top;
            max-width: 100%;
        }
    }
    .btn {
        margin-top: 30px;
    }
}

// GALLERY
#gallery,
#galleries-additional{
    margin-left: -10px;
    font-size: 0;

    a {
        display: inline-block;
        margin: 0 0 10px 10px;
    }
}
