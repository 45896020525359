// BASIC STYLES
#wrapper{
    overflow: hidden;
}

// GRID TWEAKS
.row-condensed {
    margin-left: 0;
    margin-right: 0;

    > div[class^="col-"]{
        padding-left: 0;
        padding-right: 0;
    }
}
.full-width{
    margin:0 (-$grid-gutter-width/2);
}
.no-padding-left{
    padding-left:0;
}
.no-padding-right{
    padding-right:0;
}

@media (min-width: $screen-sm){
    .full-width{
        margin: 0 -2000px;
    }
}

/* Up button */
#btn-up{
    display: block;
    position: fixed;
    width: 58px;
    height: 58px;
    right: 20px;
    bottom: 20px;
    z-index: 999;
    font-size: 0;
    opacity: 1;
    color: transparent;
    background: url(../img/btn_up_arrow.png) center 20px no-repeat $brand-primary;

    &.disabled {
        opacity: 0;
    }
    &:hover {
        background-color: darken($brand-primary, 10%);
    }
}

@media (max-width: 1170px){
    #btn-up {
        display: none;
    }
}