#e500 {
    &,
    & > body {
        background: #f1f1f1;
    }
    h1 {
        padding-top: 50px;
        font-weight: 300;
        text-transform: none;
        color: $brand-warning;
    }
    p {
        position: relative;
        z-index: 10;
    }
    #wrapper {
        text-align: center;
    }
}
